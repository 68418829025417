import React, { useState, useEffect } from 'react';
import { fetchEvents } from '../services/eventService';
import { FaRegSurprise, FaSync } from 'react-icons/fa';
import { UAParser } from 'ua-parser-js';
import { QRCodeSVG } from 'qrcode.react';

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

const formatTime = (time) => {
  const eventTime = new Date(time);
  const currentTime = new Date();
  const diffInSeconds = Math.floor((currentTime - eventTime) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes} minutes ago`;
  } else if (diffInSeconds < 10800) {
    const diffInHours = Math.floor(diffInSeconds / 3600);
    return `${diffInHours} hours ago`;
  } else {
    return eventTime.toLocaleString();
  }
};

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [expandedEventId, setExpandedEventId] = useState(null);

  const getEvents = async () => {
    try {
      const response = await fetchEvents();
      const data = response.fetched_records;
      if (Array.isArray(data)) {
        // Sort events by time in descending order
        const sortedData = data.sort((a, b) => new Date(b.time) - new Date(a.time));
        setEvents(sortedData);
      } else {
        setEvents([]);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      interval = setInterval(getEvents, 5000);
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  const toggleExpand = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">Events</h1>
          <p className="text-sm text-gray-400 mb-8">{events.length} Events</p>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="text-gray-400 hover:text-gray-200"
            onClick={getEvents}
          >
            <FaSync className="text-2xl" />
          </button>
          <label className="flex items-center text-gray-400 hover:text-gray-200">
            <input
              type="checkbox"
              className="mr-2"
              checked={autoRefresh}
              onChange={(e) => setAutoRefresh(e.target.checked)}
            />
            Auto refresh (5 sec)
          </label>
        </div>
      </div>
      <div className="rounded-lg border border-gray-750 overflow-hidden">
        <table className="min-w-full bg-gray-900 rounded-lg">
          <thead className="bg-gray-800">
            <tr>
              <th className="py-2 px-4 border-b border-gray-910 text-center">ID</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Time</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Destination URL</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Browser</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">OS</th>
            </tr>
          </thead>
          <tbody>
            {events.length === 0 ? (
              <tr>
                <td colSpan="6" className="py-4 text-center text-gray-500">
                  <FaRegSurprise className="inline-block mr-2" />
                  No Events to display
                </td>
              </tr>
            ) : (
              events.map((event) => {
                const isExpanded = expandedEventId === event.id;
                const parser = new UAParser(event.browser);
                const browserInfo = parser.getResult();

                return (
                  <React.Fragment key={event.id}>
                    <tr
                      className={`hover:bg-gray-800 cursor-pointer ${isExpanded ? 'bg-gray-800' : ''}`}
                      onClick={() => toggleExpand(event.id)}
                    >
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{event.id}</td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{formatTime(event.time)}</td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{truncateString(event.url_destination.destination, 35)}</td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{truncateString(event.browser, 24)}</td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{truncateString(event.os, 24)}</td>
                    </tr>
                    {isExpanded && (
                      <tr className="bg-gray-800">
                        <td colSpan="6" className="py-8 px-7 border-b border-gray-750">
                          <div className="flex justify-between space-x-8">
                            <div className="space-y-2">
                              <p className="font-light py-1">Event Time: <span className="font-bold text-sm">{new Date(event.time).toLocaleString()}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">Destination URL: <span className="font-bold text-sm">{event.url_destination.destination}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">Browser: <span className="font-bold text-sm">{browserInfo.browser.name} {browserInfo.browser.version}</span></p>
                              <div className="border-t border-gray-750 w-50 mx-auto"></div>
                              <p className="font-light py-1">OS: <span className="font-bold text-sm">{browserInfo.os.name} {browserInfo.os.version}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">Device: <span className="font-bold text-sm">{browserInfo.device.vendor} {browserInfo.device.model}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">URL Creation Time: <span className="font-bold text-sm">{new Date(event.url_ctime.creation_time).toLocaleString()}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">URL Status: <span className="font-bold text-sm">{event.url_status.status_link ? 'Enabled' : 'Disabled'}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">QR Code Position: <span className="font-bold text-sm">{event.url_qr_code_lon.qr_code_lon}, {event.url_qr_code_lat.qr_code_lat}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                            </div>
                            <div className="space-y-2">
                              <p className="font-light py-1">QR Code ID: <span className="font-bold text-sm">{event.url_id.url_id}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">QR Code: <QRCodeSVG value={"https://redirect.getqrtrak.com/redirect/"+event.url_id.url_id} /></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              
                            </div>
                            <div className="space-y-2">
                            <p className="font-light py-1">Device ID: <a href={`/device/${event.fingerprint_user_device}`} target="_blank" rel="noopener noreferrer" className="font-bold text-sm text-purple-500 hover:underline">{event.fingerprint_user_device}</a></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">Device location: <span className="font-bold text-sm">LON: {event.ip_location_lat} LAT: {event.ip_location_lon}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">Username: (undefined)<span className="font-bold text-sm">{event.aaa}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                              <p className="font-light py-1">Group: (undefined)<span className="font-bold text-sm">{event.group}</span></p>
                              <div className="border-t border-gray-750 mx-auto"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventsPage;