import axios from 'axios';
import { supabase } from '../components/Authentication_Supabase';

const API_URL = 'https://generate.getqrtrak.com';

export const fetchDeviceDetails = async (fingerprint) => {
  const userID = await getUserID();
  const token = await getUserSession();
  const response = await axios.post(`${API_URL}/device`, { fingerprint, userID, token });
  return response.data;
};

export const getUserID = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error || !data.session) {
    throw new Error('Error retrieving user session');
  }
  return data.session.user.id;
};

export const getUserSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error || !data.session) {
    throw new Error('Error retrieving user session');
  }
  return data.session.access_token;
};