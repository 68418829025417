import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaUser, FaKey, FaWalking } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from './Authentication_Supabase';

function TopBar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUserEmail = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (data.session) {
        setUserEmail(data.session.user.email);
      }
    };
    fetchUserEmail();
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      navigate('/login');
    } else {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <div className="flex justify-between items-center bg-gray-950 px-6 py-4">
      <div className="flex items-center space-x-6">
        <Link to="/dashboard" className="text-white hover:text-gray-300">
          <img
            src="https://cdn.getqrtrak.com/logoqrtrak.png"
            alt="Logo"
            width="40"
            height="40"
          />
        </Link>
        <div className="h-8 border-l border-gray-700"></div>
        <Link to="/qrs" className="font-light text-white hover:text-gray-300">
          QR Codes
        </Link>
        <Link to="/devices" className="font-light text-white hover:text-gray-300">
          Devices
        </Link>
        <Link to="/events" className="font-light text-white hover:text-gray-300">
          Events
        </Link>
      </div>
      <div className="flex-1 max-w-md relative">
        <input
          type="text"
          placeholder="Search"
          className="w-full px-4 py-2 bg-gray-1002 text-white rounded-md focus:outline-none pl-10"
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>
      <div className="relative" ref={dropdownRef}>
        <button
          className="font-light text-white hover:text-gray-300 flex items-center"
          onClick={toggleDropdown}
        >
          <FaUser className="mr-2" /> {userEmail}
        </button>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-gray-50 rounded-md shadow-lg py-2">
            <button className="font-light flex items-center px-4 py-2 text-white hover:bg-gray-10 w-full text-left">
              <FaKey className="mr-2" /> Account settings
            </button>
            <button
              className="font-light flex items-center px-4 py-2 text-white hover:bg-gray-10 w-full text-left"
              onClick={handleLogout}
            >
              <FaWalking className="mr-2" /> Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopBar;