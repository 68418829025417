import axios from 'axios';
import { supabase } from '../components/Authentication_Supabase'

const API_URL = 'https://generate.getqrtrak.com';


export const getUserID = async () => {
  const { data, error } = await supabase.auth.getSession();
  
  if (error || !data.session) {
    throw new Error('Error retrieving user session');
  }

  return data.session.user.id;
};

export const getUserSession = async () => {
  const { data,error } = await supabase.auth.getSession();
  if (error || !data.session) {
    throw new Error('Error retrieving user session');
  }

return data.session.access_token;

};


export const generateQRCode = async (originalUrl) => {
  const userID = await getUserID();
  const token = await getUserSession();
  const response = await axios.post(`${API_URL}/generate`, { originalUrl, 
    userID , 
    token
  }
);
  return response.data;
};

export const fetchQRCodes = async () => {
  const userID = await getUserID();
  const token = await getUserSession();
  const response = await axios.post(`${API_URL}/qrcodes`, {userID, token});
  return response.data;
};

export const deleteQRCode = async (id) => {
  const token = await getUserSession();
  const userID = await getUserID();
  await axios.post(`${API_URL}/delete-qrcode/${id}`, {userID, token});
};

export const updateQRCode = async (id, destination) => {
  const token = await getUserSession();
  await axios.post(`${API_URL}/qrcode/${id}`, { destination, token });
};

export const updateQRCodeStatus = async (id, newStatus) => {
  const token = await getUserSession();
  try {
    const response = await axios.post(`${API_URL}/qrcode/${id}/status`, {
      status_link: newStatus, token
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating QR code status');
  }
};
