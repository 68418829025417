import axios from 'axios';
import { supabase } from '../components/Authentication_Supabase';

const API_URL = 'https://generate.getqrtrak.com';

const getUserSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error || !data.session) {
    throw new Error('Error retrieving user session');
  }
  return {
    token: data.session.access_token,
    userId: data.session.user.id,
  };
};

export const fetchTotalEvents = async () => {
  const { token, userId } = await getUserSession();
  const response = await axios.get(`${API_URL}/total-events`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId,
    },
  });
  return response.data.totalEvents;
};

export const fetchTotalQRCodes = async () => {
  const { token, userId } = await getUserSession();
  const response = await axios.get(`${API_URL}/total-qrcodes`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId,
    },
  });
  return response.data.totalQRCodes;
};

export const fetchEventsPerDay = async () => {
  const { token, userId } = await getUserSession();
  const response = await axios.get(`${API_URL}/events-per-day`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId,
    },
  });
  return response.data.eventsPerDay;
};