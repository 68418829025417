import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDeviceDetails } from '../services/deviceService';
import { FaRegSurprise } from 'react-icons/fa';
import { UAParser } from 'ua-parser-js';
import Spinner from '../components/Spinner'; // Import the spinner component

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

const formatTime = (time) => {
  const eventTime = new Date(time);
  const currentTime = new Date();
  const diffInSeconds = Math.floor((currentTime - eventTime) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes} minutes ago`;
  } else if (diffInSeconds < 10800) {
    const diffInHours = Math.floor(diffInSeconds / 3600);
    return `${diffInHours} hours ago`;
  } else {
    return eventTime.toLocaleString();
  }
};

const DeviceDetailPage = () => {
  const { id } = useParams();
  const [device, setDevice] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedEventId, setExpandedEventId] = useState(null);

  useEffect(() => {
    const getDeviceDetails = async () => {
      try {
        const data = await fetchDeviceDetails(id);
        setDevice(data.device);
        setEvents(data.events);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getDeviceDetails();
  }, [id]);

  const toggleExpand = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  const parser = device ? new UAParser(device.browser) : null;
  const browserInfo = parser ? parser.getResult() : {};

  return (
    <div className="p-8">
      {error && <div className="text-red-500">{error}</div>}
      {loading ? (
        <Spinner /> // Show spinner while loading
      ) : !device ? (
        <div className="text-gray-500">Loading...</div>
      ) : (
        <div className="flex">
          <div className="w-1/6 space-y-8 py-20">
            <div className="bg-gray-900 border border-gray-910 p-6 rounded-lg shadow-md text-center">
              <h2 className="text-xl font-bold text-gray-300">Device ID</h2>
              <p className="text-3xl font-semibold text-gray-400 text-sm py-2 break-words">{device.fingerprint_user_device}</p>
              <p className="text-sm text-gray-400">Last Seen: {formatTime(device.time)}</p>
            </div>
            <div className="bg-gray-900 border border-gray-910 p-6 rounded-lg shadow-md text-center">
              <h2 className="text-xl font-bold text-gray-300">Device Info</h2>
              <p className="text-sm text-gray-400">OS: {browserInfo.os?.name} {browserInfo.os?.version}</p>
              <p className="text-sm text-gray-400">Browser: {browserInfo.browser?.name} {browserInfo.browser?.version}</p>
              <p className="text-sm text-gray-400">Device: {browserInfo.device?.vendor} {browserInfo.device?.model}</p>
            </div>
          </div>
          <div className="w-5/6 pl-8 py-20">
            <div className="rounded-lg border border-gray-300 overflow-hidden">
              <table className="min-w-full bg-gray-900 rounded-lg">
                <thead className="bg-gray-800">
                  <tr>
                    <th className="py-2 px-4 border-b border-gray-910 text-center">URL Destination</th>
                    <th className="py-2 px-4 border-b border-gray-910 text-center">Date of Hit</th>
                  </tr>
                </thead>
                <tbody>
                  {events.length === 0 ? (
                    <tr>
                      <td colSpan="2" className="py-4 text-center text-gray-500">
                        <FaRegSurprise className="inline-block mr-2" />
                        No Events to display
                      </td>
                    </tr>
                  ) : (
                    events.map((event) => (
                      <tr
                        key={event.id}
                        className={`hover:bg-gray-800 cursor-pointer ${expandedEventId === event.id ? 'bg-gray-800' : ''}`}
                        onClick={() => toggleExpand(event.id)}
                      >
                        <td className="py-2 px-4 border-b border-gray-750 text-center">{event.url_destination.destination}</td>
                        <td className="py-2 px-4 border-b border-gray-750 text-center">{formatTime(event.time)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceDetailPage;