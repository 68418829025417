import React, { useState, useEffect } from 'react';
import { fetchUsers } from '../services/userService';
import { FaRegSurprise } from 'react-icons/fa';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await fetchUsers();
        setUsers(data);
      } catch (error) {
        setError(error.message);
      }
    };

    getUsers();
  }, []);

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold">Users</h1>
      <p className="text-sm text-gray-400 mb-8">{users.length} Users</p>
      <div className="rounded-lg border border-gray-750 overflow-hidden">
        <table className="min-w-full bg-gray-900">
          <thead className="bg-gray-800">
            <tr>
              <th className="py-2 px-4 border-b border-gray-910">Name</th>
              <th className="py-2 px-4 border-b border-gray-910">Email</th>
              <th className="py-2 px-4 border-b border-gray-910">ID</th>
              <th className="py-2 px-4 border-b border-gray-910">Device</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="4" className="py-4 text-center text-gray-500">
                  <FaRegSurprise className="inline-block mr-2" />
                  No Users to display
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user.id} className="hover:bg-gray-100 cursor-pointer">
                  <td className="py-2 px-4 border-b border-gray-910">{user.name}</td>
                  <td className="py-2 px-4 border-b border-gray-910">{user.email}</td>
                  <td className="py-2 px-4 border-b border-gray-910">{user.id}</td>
                  <td className="py-2 px-4 border-b border-gray-910">{user.device}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersPage;