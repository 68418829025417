import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar';
import Dashboard from './components/Dashboard';
import UsersPage from './pages/UsersPage';
import EventsPage from './pages/EventsPage';
import QRPage from './pages/QRPage';
import DevicePage from './pages/DevicePage';
import DeviceDetailPage from './pages/DeviceDetailPage';
import CreateQRCodePage from './pages/CreateQRCodePage';
import LoginPage from './pages/LoginPage';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';

const supabase = createClient('https://ygzocglzsiilbeezuori.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInlnem9jZ2x6c2lpbGJlZXp1b3JpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4OTAzNzUsImV4cCI6MjA0NDQ2NjM3NX0.DFaj2U9p4dvESnPSKvXRZu2sK-ovPZ1LK79DXcQJxzI')

const customTheme = {
  default: {
    colors: {
      brand: 'hsl(265, 52%, 47%)',
      brandAccent: 'hsl(265, 48%, 35%)',
      brandButtonText: '#ffffff',
      inputBackground: '#ffffff',
      inputText: '#333333',
      inputBorder: '#444444',
      inputLabelText: '#ffffff',
      messageTextDanger: 'hsl(355, 65%, 45%)',
      defaultButtonBackground: 'hsl(265, 52%, 47%)',
      defaultButtonBackgroundHover: 'hsl(265, 48%, 35%)',
      defaultButtonText: '#ffffff',
      messageText: '#ffffff',
      background: '#1a1a1a',
    },
    fonts: {
      bodyFontFamily: '"Inter", sans-serif',
      headingFontFamily: '"Inter", sans-serif',
      buttonFontFamily: '"Inter", sans-serif',
    },
    fontSizes: {
      baseBodySize: '16px',
      baseHeadingSize: '18px',
      baseInputSize: '16px',
      baseLabelSize: '14px',
      baseButtonSize: '16px',
    },
    borders: {
      inputBorderRadius: '1px',
      buttonBorderRadius: '1px',
    },
  },
};

const App = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="bg-gray-900 text-white min-h-screen font-sans">
      <Router>
        {session ? (
          <>
            <TopBar />
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/qrs" element={<QRPage />} />
              <Route path="/devices" element={<DevicePage />} />
              <Route path="/device/:fingerprint" element={<DeviceDetailPage />} />
              <Route path="/create-qr" element={<CreateQRCodePage />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="*" element={<LoginPage />} />
          </Routes>
        )}
      </Router>
    </div>
  );
};

export default App;