import React, { useState } from 'react';
import { generateQRCode } from '../services/qrCodeService';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const CreateQRCodePage = () => {
  const [url, setUrl] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [progress, setProgress] = useState(100);
  const [qrType, setQrType] = useState(null);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await generateQRCode(url, isEnabled, latitude, longitude, webhookUrl);
      setMessage(`QR code generated for ${data.originalUrl}`);
      setMessageType('success');
      setUrl('');
      setIsEnabled(true);
      setLatitude('');
      setLongitude('');
      setWebhookUrl('');
    } catch (err) {
      setMessage(`Error: ${err.message}`);
      setMessageType('error');
    }
  };

  // Hide the message after 10 seconds and decrease the progress bar
  React.useEffect(() => {
    if (message) {
      setProgress(100);
      const timer = setInterval(() => {
        setProgress((prev) => (prev > 0 ? prev - 1 : 0));
      }, 100);

      const hideMessage = setTimeout(() => {
        setMessage(null);
      }, 10000);

      return () => {
        clearTimeout(hideMessage);
        clearInterval(timer);
      };
    }
  }, [message]);

  const handleOptionChange = (option) => {
    setOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((o) => o !== option)
        : [...prevOptions, option]
    );
  };

  return (
    <div className="p-8 flex justify-center items-center min-h-screen">
      <div className="w-full max-w-lg">
        <h1 className="text-2xl font-bold mb-4 text-center">Create QR Code</h1>
        <div className="flex justify-center space-x-4 mb-8">
          <div
            className={`bg-transparent border border-gray-750 text-gray-500 px-4 py-2 rounded-md hover:bg-gray-100 flex items-center cursor-pointer ${
              qrType === 'simple' ? 'bg-gray-200' : ''
            }`}
            onClick={() => setQrType('simple')}
          >
            <span className="text-2xl mr-2">+</span> Simple QR Code
          </div>
          <div
            className={`bg-transparent border border-gray-750 text-gray-500 px-4 py-2 rounded-md hover:bg-gray-100 flex items-center cursor-pointer ${
              qrType === 'dynamic' ? 'bg-gray-200' : ''
            }`}
            onClick={() => setQrType('dynamic')}
          >
            <span className="text-2xl mr-2">+</span> Dynamic QR Code
          </div>
        </div>
        {message && (
          <div className={`message-box ${messageType}`}>
            {message}
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
        )}
        {qrType && (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-white">Destination URL</label>
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="bg-gray-900 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Enable QR Code</label>
              <input
                type="checkbox"
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
                className="mt-1"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Options</label>
              <div className="flex space-x-4">
                <div>
                  <input
                    type="checkbox"
                    id="position"
                    checked={options.includes('position')}
                    onChange={() => handleOptionChange('position')}
                  />
                  <label htmlFor="position" className="ml-2 text-white">QR Code Position</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="webhook"
                    checked={options.includes('webhook')}
                    onChange={() => handleOptionChange('webhook')}
                  />
                  <label htmlFor="webhook" className="ml-2 text-white">Webhook</label>
                </div>
              </div>
            </div>
            {options.includes('position') && (
              <>
                <div>
                  <label className="block text-sm font-medium text-white">Latitude</label>
                  <input
                    type="text"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    className="bg-gray-900 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-white">Longitude</label>
                  <input
                    type="text"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    className="bg-gray-900 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </>
            )}
            {options.includes('webhook') && (
              <div>
                <label className="block text-sm font-medium text-white">Webhook URL</label>
                <input
                  type="text"
                  value={webhookUrl}
                  onChange={(e) => setWebhookUrl(e.target.value)}
                  className="bg-gray-900 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            )}
            <div className="flex justify-center space-x-4">
            <button
                type="button"
                className="border border-red-500 text-red-500 px-4 py-2 rounded-md hover:bg-red-100 flex items-center"
                onClick={() => navigate('/qrs')}
              >
                <FaTimes className="mr-2" /> Cancel
              </button>
              <button
                type="submit"
                className="border border-green-500 text-green-500 px-4 py-2 rounded-md hover:bg-green-100 flex items-center"
              >
                <FaCheck className="mr-2" /> Create QR Code
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateQRCodePage;