import React, { useEffect, useState } from 'react';
import { fetchTotalEvents, fetchTotalQRCodes, fetchEventsPerDay } from '../services/statsService';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

function Dashboard() {
  const [stats, setStats] = useState({
    totalEvents: 0,
    totalQRCodes: 0,
    monthlyLimits: '10,000',
    monthlyConsumption: '6,890',
  });
  const [eventsPerDay, setEventsPerDay] = useState([]);
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, value: 0, date: '' });

  useEffect(() => {
    const fetchStats = async () => {
      const totalEvents = await fetchTotalEvents();
      const totalQRCodes = await fetchTotalQRCodes();
      const eventsPerDay = await fetchEventsPerDay();

      setStats((prevStats) => ({
        ...prevStats,
        totalEvents,
        totalQRCodes,
      }));

      const allDaysOfMonth = generateAllDaysOfMonth();
      const eventsPerDayArray = allDaysOfMonth.map(date => ({
        date,
        count: eventsPerDay[date] || 0,
      }));

      setEventsPerDay(eventsPerDayArray);
    };

    fetchStats();
  }, []);

  const generateAllDaysOfMonth = () => {
    const now = new Date();
    const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(now.getFullYear(), now.getMonth(), i);
      daysArray.push(day.toISOString().split('T')[0]);
    }
    return daysArray;
  };

  const data = {
    labels: eventsPerDay.map((event) => event.date),
    datasets: [
      {
        label: 'Events per Day',
        data: eventsPerDay.map((event) => event.count),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        external: (context) => {
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            setTooltip({ visible: false });
            return;
          }

          const { offsetLeft: positionX, offsetTop: positionY } = context.chart.canvas;
          const dataIndex = tooltipModel.dataPoints[0].dataIndex;
          const date = data.labels[dataIndex];
          const value = tooltipModel.dataPoints[0].raw;

          setTooltip({
            visible: true,
            x: positionX + tooltipModel.caretX,
            y: positionY + tooltipModel.caretY,
            value,
            date,
          });
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="p-8">
      <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-xl font-bold text-gray-300">Total Events</h2>
          <p className="text-3xl font-semibold text-white">{stats.totalEvents}</p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-xl font-bold text-gray-300">Total QR Codes Generated</h2>
          <p className="text-3xl font-semibold text-white">{stats.totalQRCodes}</p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-xl font-bold text-gray-300">Monthly Limits</h2>
          <p className="text-3xl font-semibold text-white">{stats.monthlyLimits}</p>
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-xl font-bold text-gray-300">Monthly Consumption</h2>
          <p className="text-3xl font-semibold text-white">{stats.monthlyConsumption}</p>
        </div>
      </div>
      <div className="mt-8 bg-gray-800 p-6 rounded-lg shadow-md" style={{ width: '50%', height: '400px' }}>
        <h2 className="text-xl font-bold text-gray-300 mb-4">Events per Day (Current Month)</h2>
        <div className="relative" style={{ width: '100%', height: '90%' }}>
          <Bar data={data} options={options} />
          {tooltip.visible && (
            <div
              className="absolute bg-gray-700 text-white text-sm px-2 py-1 rounded"
              style={{ top: tooltip.y, left: tooltip.x }}
            >
              {tooltip.value} events on {tooltip.date}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;