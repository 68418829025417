import React, { useState, useEffect } from 'react';
import { fetchQRCodes, deleteQRCode } from '../services/qrCodeService';
import { fetchEvents } from '../services/eventService';
import { FaRegSurprise, FaSync, FaEdit, FaTrash, FaToggleOn, FaToggleOff, FaDownload } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import { Link, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode';

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

const formatTime = (time) => {
  const eventTime = new Date(time);
  return eventTime.toLocaleString();
};

const QRPage = () => {
  const [qrCodes, setQRCodes] = useState([]);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [expandedQRCodeId, setExpandedQRCodeId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [qrCodeToDelete, setQRCodeToDelete] = useState(null);
  const navigate = useNavigate();

  const getQRCodes = async () => {
    try {
      const response = await fetchQRCodes();
      const data = response.fetched_records;
      if (Array.isArray(data)) {
        const sortedData = data.sort((a, b) => new Date(b.creation_time) - new Date(a.creation_time));
        setQRCodes(sortedData);
      } else {
        setQRCodes([]);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const getQRCodeDetails = async (qrCodeId) => {
    try {
      const response = await fetchEvents();
      const data = response.fetched_records.filter(event => event.url_id === qrCodeId);
      const totalHits = data.length;
      const lastHit = data.length > 0 ? data[0].time : null;
      return { totalHits, lastHit };
    } catch (error) {
      console.error('Error fetching QR code details:', error);
      return { totalHits: 0, lastHit: null };
    }
  };

  const downloadQRCode = async (qrCodeId) => {
    try {
      const url = `https://redirect.getqrtrak.com/redirect/${qrCodeId}`;
      const dataUrl = await QRCode.toDataURL(url);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${qrCodeId}.png`;
      link.click();
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  useEffect(() => {
    getQRCodes();
  }, []);

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      interval = setInterval(getQRCodes, 5000);
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  const toggleExpand = async (qrCodeId) => {
    if (expandedQRCodeId === qrCodeId) {
      setExpandedQRCodeId(null);
    } else {
      const details = await getQRCodeDetails(qrCodeId);
      setQRCodes(qrCodes.map(qrCode => qrCode.url_id === qrCodeId ? { ...qrCode, ...details } : qrCode));
      setExpandedQRCodeId(qrCodeId);
    }
  };

  const handleDeleteClick = (qrCodeId) => {
    setQRCodeToDelete(qrCodeId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteQRCode(qrCodeToDelete);
      setQRCodes(qrCodes.filter(qrCode => qrCode.url_id !== qrCodeToDelete));
      setShowDeleteModal(false);
      setQRCodeToDelete(null);
    } catch (error) {
      console.error('Error deleting QR code:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setQRCodeToDelete(null);
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">QR Codes</h1>
          <p className="text-sm text-gray-400 mb-8">{qrCodes.length} QR Codes</p>
        </div>
        <button
          className="bg-transparent border border-gray-300 text-gray-500 px-4 py-2 rounded-md hover:bg-gray-100 flex items-center"
          onClick={() => navigate('/create-qr')}
        >
          <span className="text-2xl mr-2">+</span> Create QR Code
        </button>
      </div>
      <div className="rounded-lg border border-gray-300 overflow-hidden">
        <table className="min-w-full bg-gray-900 rounded-lg">
          <thead className="bg-gray-800">
            <tr>
              <th className="py-2 px-4 border-b border-gray-910 text-center">QR Code</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Creation Date</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Destination URL</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {qrCodes.length === 0 ? (
              <tr>
                <td colSpan="4" className="py-4 text-center text-gray-500">
                  <FaRegSurprise className="inline-block mr-2" />
                  No QR Codes to display
                </td>
              </tr>
            ) : (
              qrCodes.map((qrCode) => {
                const isExpanded = expandedQRCodeId === qrCode.url_id;
                return (
                  <React.Fragment key={qrCode.url_id}>
                    <tr
                      className={`hover:bg-gray-800 cursor-pointer ${isExpanded ? 'bg-gray-800' : ''}`}
                      onClick={() => toggleExpand(qrCode.url_id)}
                    >
                      <td className="py-2 px-4 border-b border-gray-750 text-center">
                        <QRCodeSVG value={`https://redirect.getqrtrak.com/redirect/${qrCode.url_id}`} />
                      </td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{formatTime(qrCode.creation_time)}</td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">{truncateString(qrCode.destination, 35)}</td>
                      <td className="py-2 px-4 border-b border-gray-750 text-center">
                        <button className="text-blue-500 hover:text-blue-700 mx-1">
                          <FaEdit />
                        </button>
                        <button className="text-green-500 hover:text-green-700 mx-1">
                          {qrCode.status_link ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700 mx-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(qrCode.url_id);
                          }}
                        >
                          <FaTrash />
                        </button>
                        <button
                          className="text-gray-500 hover:text-gray-700 mx-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadQRCode(qrCode.url_id);
                          }}
                        >
                          <FaDownload />
                        </button>
                      </td>
                    </tr>
                    {isExpanded && (
                      <tr className="bg-gray-800">
                        <td colSpan="4" className="py-8 px-7 border-b border-gray-750">
                          <div className="space-y-2">
                            <p className="font-light py-1">Full Destination URL: <span className="font-bold text-sm">{qrCode.destination}</span></p>
                            <div className="border-t border-gray-750 mx-auto"></div>
                            <p className="font-light py-1">QR Code ID: <span className="font-bold text-sm">{qrCode.url_id}</span></p>
                            <div className="border-t border-gray-750 mx-auto"></div>
                            <p className="font-light py-1">Total Hits: <span className="font-bold text-sm">{qrCode.totalHits || 0}</span></p>
                            <div className="border-t border-gray-750 mx-auto"></div>
                            <p className="font-light py-1">Last Hit Date: <span className="font-bold text-sm">{qrCode.lastHit ? formatTime(qrCode.lastHit) : 'N/A'}</span></p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-white">Confirm Delete</h2>
            <p className="mb-4 text-white">Are you sure you want to delete this QR code?</p>
            <p className="mb-4 text-white">This will also delete all related events.</p>
            <div className="flex justify-end space-x-4">
              <button
                className="border border-gray-500 text-gray-500 px-4 py-2 rounded-md hover:bg-gray-100"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
              <button
                className="border border-red-500 text-red-500 px-4 py-2 rounded-md hover:bg-red-100"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRPage;