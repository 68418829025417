import React, { useState, useEffect } from 'react';
import { fetchEvents } from '../services/eventService';
import { FaRegSurprise, FaSync } from 'react-icons/fa';

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

const formatTime = (time) => {
  const eventTime = new Date(time);
  const currentTime = new Date();
  const diffInSeconds = Math.floor((currentTime - eventTime) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes} minutes ago`;
  } else if (diffInSeconds < 10800) {
    const diffInHours = Math.floor(diffInSeconds / 3600);
    return `${diffInHours} hours ago`;
  } else {
    return eventTime.toLocaleString();
  }
};

const DevicePage = () => {
  const [devices, setDevices] = useState([]);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const getDevices = async () => {
    try {
      const response = await fetchEvents();
      const data = response.fetched_records;
      if (Array.isArray(data)) {
        // Group by device ID and calculate last seen and total hits
        const deviceMap = data.reduce((acc, event) => {
          const { fingerprint_user_device, time, url_destination } = event;
          if (!acc[fingerprint_user_device]) {
            acc[fingerprint_user_device] = {
              deviceId: fingerprint_user_device,
              lastSeen: new Date(time),
              lastSeenUrl: url_destination.destination,
              totalHits: 1,
            };
          } else {
            acc[fingerprint_user_device].totalHits += 1;
            if (new Date(time) > acc[fingerprint_user_device].lastSeen) {
              acc[fingerprint_user_device].lastSeen = new Date(time);
              acc[fingerprint_user_device].lastSeenUrl = url_destination.destination;
            }
          }
          return acc;
        }, {});
        setDevices(Object.values(deviceMap));
      } else {
        setDevices([]);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      interval = setInterval(getDevices, 5000);
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  const handleRowClick = (deviceId) => {
    window.open(`/device/${deviceId}`, '_blank');
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">Devices</h1>
          <p className="text-sm text-gray-400 mb-8">{devices.length} Devices</p>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="text-gray-400 hover:text-gray-200"
            onClick={getDevices}
          >
            <FaSync className="text-2xl" />
          </button>
          <label className="flex items-center text-gray-400 hover:text-gray-200">
            <input
              type="checkbox"
              className="mr-2"
              checked={autoRefresh}
              onChange={(e) => setAutoRefresh(e.target.checked)}
            />
            Auto refresh (5 sec)
          </label>
        </div>
      </div>
      <div className="rounded-lg border border-gray-750 overflow-hidden">
        <table className="min-w-full bg-gray-900 rounded-lg">
          <thead className="bg-gray-800">
            <tr>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Name</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Device ID</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Last Seen</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Last Seen URL</th>
              <th className="py-2 px-4 border-b border-gray-910 text-center">Total Hits</th>
            </tr>
          </thead>
          <tbody>
            {devices.length === 0 ? (
              <tr>
                <td colSpan="4" className="py-4 text-center text-gray-500">
                  <FaRegSurprise className="inline-block mr-2" />
                  No Devices to display
                </td>
              </tr>
            ) : (
              devices.map((device) => (
                <tr
                  key={device.deviceId}
                  className="hover:bg-gray-800 cursor-pointer"
                  onClick={() => handleRowClick(device.deviceId)}
                >
                  <td className="py-2 px-4 border-b border-gray-750 text-center">(undefined)</td>
                  <td className="py-2 px-4 border-b border-gray-750 text-center">{device.deviceId}</td>
                  <td className="py-2 px-4 border-b border-gray-750 text-center">{formatTime(device.lastSeen)}</td>
                  <td className="py-2 px-4 border-b border-gray-750 text-center">{truncateString(device.lastSeenUrl, 35)}</td>
                  <td className="py-2 px-4 border-b border-gray-750 text-center">{device.totalHits}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DevicePage;